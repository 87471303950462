import { useState, useRef, useEffect } from 'react'
import va from '@vercel/analytics';
import axios from 'axios'

import LeftArrow from '@components/Icons/LeftArrow.jsx'
import RightArrow from '@components/Icons/RightArrow.jsx'
import StarSolid from '@components/Icons/StarSolid.jsx'
import StarHalf from '@components/Icons/StarHalf.jsx'

import { splitLastOccurrence, convertToCurrency } from '@utils/utils.js'


const BOOST_URL =
    'https://services.mybcapps.com/bc-sf-filter/search?shop=monicaandandyonline.myshopify.com&limit=70&q='

const ProductsCarousel = ({
    carouselData,
    aspect = '2/3',
    carouselType,
    productData,
}) => {
    const data = carouselData

    const [products, setProducts] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const maxScrollWidth = useRef(0)
    const [currentIndex, setCurrentIndex] = useState(0)
    const carousel = useRef(null)
    const totalSlides = data.items.length
    const [scrollX, setScrollX] = useState(0)
    const [scrollEnd, setScrollEnd] = useState(false)

    let tileWidthClass = ''

    const movePrev = () => {
        if (carousel.current !== null) {
            let child
            let frameWidth = carousel.current.firstChild.offsetWidth

            let scrollFrame =
                Math.ceil(carousel.current.offsetWidth / frameWidth) - 1
            if (window.innerWidth > 800) {
                carousel.current.scrollLeft -= frameWidth * 3 + 10
            } else {
                carousel.current.scrollLeft -= frameWidth * 2 + 10
            }
            // setCurrentIndex((prevState) => prevState + 1)
        }
    }

    const moveNext = () => {
        if (carousel.current !== null) {
            let frameWidth = carousel.current.firstChild.offsetWidth

            let scrollFrame =
                Math.ceil(carousel.current.offsetWidth / frameWidth) - 1
            console.log(scrollFrame)
            if (window.innerWidth > 800) {
                carousel.current.scrollLeft += frameWidth * 3 + 10
            } else {
                carousel.current.scrollLeft += frameWidth * 2 + 10
            }
        }
    }

    useEffect(() => {
        maxScrollWidth.current = carousel.current
            ? carousel.current.scrollWidth - carousel.current.offsetWidth
            : 0
    }, [])

    const scrollFunction = (e) => {
        setScrollX(carousel.current.scrollLeft)
        if (
            Math.floor(
                carousel.current.scrollWidth - carousel.current.scrollLeft
            ) <= carousel.current.offsetWidth
        ) {
            setScrollEnd(true)
        } else {
            setScrollEnd(false)
        }
    }

    // console.log('Carousel data.type', data.type)

    return (
        <div className="carousel-outer max-w-[90vw] 2xl:max-w-[1400px] mx-auto">
            <div className="carousel-inner wrap-[90vw] overflow-x-hidden lg:wrap-xl mx-auto">
                <div className="carousel mx-auto wrap-[90vw] overflow-x-hidden lg:wrap-xl relative">
                    <div className="carousel-header relative flex flex-col lg:flex-row items-center mb-3 mr-4">
                        <h2 className="text-lg md:text-xl lg:text-2xl xl:text-3xl max-w-[400px]">
                            {data && data.title
                                ? data.title
                                : 'Featured Products'}
                        </h2>
                        <div className="carousel-header-wrap justify-center lg:justify-end lg:ml-auto flex flex-col lg:flex-row items-center gap-3">
                            <div className="shop-all-links flex items-center gap-3">
                                <div className="shop-all-link">
                                    <a
                                        href={
                                            data && data.link
                                                ? data.link
                                                : '/collections/baby-clothes'
                                        }
                                        className="text-fl-sm underline lg:mr-3"
                                    >
                                        {data && data.linkText
                                            ? data.linkText
                                            : ''}
                                    </a>
                                </div>
                            </div>

                            <div className="carousel-nav flex items-center gap-3">
                                <div
                                    className={`angle-left ${
                                        scrollX === 0
                                            ? 'cursor-not-allowed opacity-50'
                                            : 'cursor-pointer'
                                    }`}
                                    onClick={() => movePrev()}
                                    disabled={scrollX === 0}
                                >
                                    <LeftArrow width="w-[32px]" />
                                </div>
                                <div
                                    className={`angle-right ${
                                        scrollEnd
                                            ? 'cursor-not-allowed opacity-50'
                                            : 'cursor-pointer'
                                    }`}
                                    onClick={() => moveNext()}
                                    disabled={scrollEnd}
                                >
                                    <RightArrow width="w-[32px]" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="relative overflow-x-hidden">
                        <div
                            onScroll={(e) => scrollFunction(e)}
                            ref={carousel}
                            className="carousel-container relative grid grid-flow-col auto-cols-[minmax(200px,1fr)] lg:auto-cols-[minmax(240px,1fr)] gap-1 overflow-x-scroll scroll-smooth snap-x snap-mandatory touch-pan-x z-0"
                        >
                            {data &&
                                data.items.map((item, index) => {
                                    // console.log('item', item)

                                    const [ratingBase, ratingDecimal] =
                                        splitLastOccurrence(item.rating, '.')

                                    const ratingBaseInt = parseInt(ratingBase)

                                    let ratingDecimalString =
                                        '0.' + ratingDecimal

                                    let ratingDecimalFloat =
                                        parseFloat(ratingDecimalString)

                                    const decimalToPercentage = (decimal) => {
                                        return decimal * 100
                                    }
                                    const percentage =
                                        decimalToPercentage(ratingDecimalFloat)

                                    // console.log('percentage', percentage)

                                    const percentString =
                                        percentage.toString() + '%'

                                    // console.log(
                                    //     'ratingBase, ratingDecimal, ratingBaseInt',
                                    //     ratingBase,
                                    //     ratingDecimal,
                                    //     ratingBaseInt,
                                    //     ratingDecimalFloat
                                    // )

                                    const numberOfReviews = item.numberOfReviews || ''

                                    let imageUrl = item.imageUrl
                                    const [before, after] = splitLastOccurrence(
                                        imageUrl,
                                        '.'
                                    )
                                    let resizedImageUrl =
                                        before + '_400x600.' + after
                                    return (
                                        <div
                                            key={index}
                                            className={`carousel-item relative h-auto product-item aspect-[2/3]`}
                                        >
                                            <div className="product-image">
                                                <a
                                                    href={item.slug}
                                                    className={`
                                                     h-auto product-item aspect-[2/3] object-cover
                                                     block bg-origin-padding bg-left-top bg-cover bg-no-repeat z-0 relative hover:opacity-80`}
                                                    style={{
                                                        backgroundImage: `url(${
                                                            resizedImageUrl ||
                                                            ''
                                                        })`,
                                                    }}
                                                    aria-label={`Go to ${
                                                        item.itemName
                                                            ? item.itemName +
                                                              ' category page'
                                                            : 'category page'
                                                    }`}
                                                    onClick={() => {va.track('Best Selling Carousel Tile Click', {
                                                        itemType: item.itemType,
                                                        itemName: item.itemName,
                                                        itemLink: item.slug,
                                                    })}}
                                                >
                                                    <img
                                                        src={
                                                            resizedImageUrl ||
                                                            ''
                                                        }
                                                        alt={item.itemName}
                                                        className={`w-full product-item aspect-[2/3] object-cover
                                                    `}
                                                    />
                                                    <span className="sr-only">{item.itemName ? item.itemName : ''}</span>
                                                    <span className="sr-only">{item.itemName}</span>
                                                </a>
                                            </div>
                                            <div className="product-details">
                                                <h3
                                                    className={`mx-auto text-left my-2 text-base pr-2 line-clamp-2 !leading-[1.3] min-h-[45px]`}
                                                >
                                                    <a
                                                        className="hover:underline"
                                                        href={item.slug}
                                                    >
                                                        {item.itemName.replace(
                                                            '_',
                                                            ' - '
                                                        )}
                                                    </a>
                                                </h3>
                                                {/* {item.parentProductId ? (
                                                    <div
                                                    data-bv-show="rating_summary"
                                                    data-bv-product-id={item.parentProductId}
                                                ></div>
                                                ) : null} */}

                                                <div className="rating-stars flex items-center gap-1">
                                                    {/* {for(var i = 0; i < ratingBaseInt; i++) {
                                                        return (
                                                            <StarSolid width="w-[16px]" />
                                                        )
                                                    }} */}
                                                    {Array(ratingBaseInt)
                                                        .fill(1)
                                                        .map((el, i) => {
                                                            // console.log('i', i)
                                                            return (
                                                                <StarSolid
                                                                    key={i}
                                                                    width="w-[16px]"
                                                                />
                                                            )
                                                        })}
                                                    {ratingDecimal > 0 ? (
                                                        <StarHalf
                                                            key="star-5"
                                                            width="w-[22px]"
                                                            decimal={
                                                                ratingDecimalFloat
                                                            }
                                                        />
                                                    ) : null}
                                                    {item.rating &&
                                                    item.rating > 0 ? (
                                                        <span className="ratings-number text-xs">
                                                            ({item.rating})
                                                        </span>
                                                    ) : null}

                                                    {numberOfReviews ? (
                                                        <span className="ratings-number text-xs">
                                                            &nbsp;({numberOfReviews})
                                                        </span>
                                                    ) : null}
                                                </div>

                                                <div className="available text-xs mt-2 font-normal">
                                                    Available in {item.colors}{' '}
                                                    {item.colors > 1
                                                        ? 'colors'
                                                        : 'color'}
                                                </div>

                                                <div className="product-price text-left mt-2 text-base">
                                                    {item.price
                                                        ? '$' +
                                                          convertToCurrency(
                                                              item.price
                                                          )
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductsCarousel
