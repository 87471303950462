const StarSolid = ({ width }) => {
    return (
        <div className={`star-solid-icon ${width}`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 275.38 288.15">
                <g fill="currentColor">
                    <path
                        stroke="currentColor"
                        strokeWidth="5.76"
                        d="M137.69 9.46L51.37 279.95 267.18 107.3 8.2 107.3 224.02 279.95 137.69 9.46z"
                    ></path>
                    <path d="M137.69 9.46L51.37 279.95 267.18 107.3 8.2 107.3 224.02 279.95 137.69 9.46z"></path>
                </g>
            </svg>
        </div>
    )
}

export default StarSolid
