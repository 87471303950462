const StarHalf = ({ width, decimal }) => {
    // console.log('decimal', decimal)

    const decimalToPercentage = (decimal) => {
        return decimal * 100
    }
    const percentage = decimalToPercentage(decimal)

    // console.log('percentage', percentage)

    const percentString = percentage.toString() + '%'
    // console.log('percentString', percentString)

    return (
        <div className={`star-half-icon ${width} relative top-[3px]`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 275.38 288.15">
                <defs>
                    <linearGradient id={`half-${decimal}`} className="linear">
                        <stop offset="0%" stopColor="#414042" />
                        <stop offset={percentString} stopColor="#414042" />
                        <stop offset={percentString} stopColor="white" />
                        <stop offset="100%" stopColor="white" />
                    </linearGradient>
                </defs>
                <g fill={`url(#half-${decimal})`} stroke="#414042" strokeWidth="4">
                    <polygon points="100,10 40,198 190,78 10,78 160,198" />
                    <polygon
                        points="100,10 40,198 190,78 10,78 160,198"
                        stroke="none"
                    />
                </g>
            </svg>
        </div>
    )
}

export default StarHalf
